import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/modules';

$(document).ready(() => {
    var elements = $('.swiper-slides');
    $(elements).each(function( index ) {
        initSwiperSLider($(this));
    });
});

function initSwiperSLider(elem)
{
    var id = elem.attr('id');
    if (id == '') {
        console.log('initSwiper : aucun id de défini');
        return;
    }
    var speed = parseInt(elem.data('speed'));
    var pause_hover = elem.data('pause-hover');
    var repeat = elem.data('repeat');
    var fade = elem.data('fade');
    var arrows_desktop = elem.data('arrows-desktop');
    var arrows_mobile = elem.data('arrows-mobile');
    var dots_desktop = elem.data('dots-desktop');
    var dots_mobile = elem.data('dots-mobile');
    var autoplay = elem.data('autoplay');
    var autoplay_speed = elem.data('autoplay-speed');


    const config_swiper = {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: repeat,
        speed: speed,
        fadeEffect: {
            crossFade: true
        },
    };

    const modules = [];

    //Pagination
    if (dots_desktop || dots_mobile) {
        modules.push(Pagination);
        config_swiper.pagination = {
            el: "."+id+"-swiper-pagination",
            clickable: true
        };
    }

    //Navigation
    if (arrows_desktop || arrows_mobile) {
        modules.push(Navigation);
        config_swiper.navigation = {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        };
    }

    //Fade Effect
    if (fade) {
        modules.push(EffectFade);
        config_swiper.effect = "fade";
    }

    //AutoPlay
    if (autoplay) {
        modules.push(Autoplay);
        config_swiper.autoplay = {
            delay: autoplay_speed,
            disableOnInteraction: false,
            pauseOnMouseEnter: pause_hover
        };
    }

    //Modules
    config_swiper.modules = modules;

    //Init Swiper
    var swiper = new Swiper("#"+id, config_swiper);
}
